exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-account-tsx": () => import("./../../../src/pages/account/account.tsx" /* webpackChunkName: "component---src-pages-account-account-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("./../../../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-otp-tsx": () => import("./../../../src/pages/otp.tsx" /* webpackChunkName: "component---src-pages-otp-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-process-login-tsx": () => import("./../../../src/pages/process-login.tsx" /* webpackChunkName: "component---src-pages-process-login-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-restriction-tsx": () => import("./../../../src/pages/restriction.tsx" /* webpackChunkName: "component---src-pages-restriction-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/set-password.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-terms-and-condition-tsx": () => import("./../../../src/pages/terms-and-condition.tsx" /* webpackChunkName: "component---src-pages-terms-and-condition-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-track-complete-tsx": () => import("./../../../src/pages/track-complete.tsx" /* webpackChunkName: "component---src-pages-track-complete-tsx" */),
  "component---src-pages-track-order-tsx": () => import("./../../../src/pages/track-order.tsx" /* webpackChunkName: "component---src-pages-track-order-tsx" */),
  "component---src-pages-transaction-declined-tsx": () => import("./../../../src/pages/transaction-declined.tsx" /* webpackChunkName: "component---src-pages-transaction-declined-tsx" */),
  "component---src-pages-unserviced-tsx": () => import("./../../../src/pages/unserviced.tsx" /* webpackChunkName: "component---src-pages-unserviced-tsx" */),
  "component---src-templates-products-index-js": () => import("./../../../src/templates/Products/index.js" /* webpackChunkName: "component---src-templates-products-index-js" */)
}

